import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Button from 'common/components/Button';

import { BannerWrapper } from './banner.style';

const FeatureBannerSection = ({
  row,
  contentWrapper,
  description,
  buttonWrapper,
  fillButton,
  bannerTitle,
  bannerDescription,
}) => {
  const [email, setEmail] = useState('');
  const [buttonActive, setButtonActive] = useState(false);
  const [showEmailWarning, setShowEmailWarning] = useState(false);

  const validateEmail = async (email) => {
    const emailDomain = email.split('@')[1];

    const response = await fetch(
      `https://m.api.azr.clodura.com/freemailpros?msp_eq=${emailDomain}`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.length === 0; // Returns true if email domain is not in the list
    } else {
      return false; // Error occurred, treat as invalid
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmailValid = await validateEmail(email);

    if (!isEmailValid) {
      setShowEmailWarning(true); // Display the warning message
      return;
    }

    const body = { data: { email } };

    const response = await fetch(
      'https://m.api.corp.azr.clodura.ai/api/clodura-signups/',
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          Authorization: `bearer ${process.env.STRAPI_TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );

    if (response.ok) {
      localStorage.setItem('email', email);
      window.location.href = '/request-demo/';
    } else {
      window.alert('Invalid/Registered email, please try again');
    }
  };

  useEffect(() => {
    if (email && email.length > 0) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  }, [email]);

  return (
    <BannerWrapper id="banner_section">
      <Box {...row}>
        <Box {...contentWrapper}>
          <h1 className="headings-container">{bannerTitle}</h1>
          <Text {...description} content={bannerDescription} />
          <Box {...buttonWrapper} className="email-signup">
            <input
              type="text"
              placeholder="Business Email"
              className="fill-input"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></input>
            <Button
              {...fillButton}
              title="Book A Demo"
              onClick={handleSubmit}
              style={{ backgroundColor: buttonActive ? null : 'gray' }}
              disabled={!buttonActive}
            />
          </Box>
          {showEmailWarning && ( // Display the warning message if showEmailWarning is true
            <p
              style={{
                color: 'red',
                fontSize: '12px',
                textAlign: 'center', // Center the warning message
              }}
            >
              Please enter your corporate email address only to book a demo!
            </p>
          )}
        </Box>
      </Box>
    </BannerWrapper>
  );
};

FeatureBannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageArea: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  logoStyle: PropTypes.object,
  fillButton: PropTypes.object,
};

FeatureBannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    mb: '40px',
  },
  imageWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    flexBox: true,
    justifyContent: 'center',
  },
  imageArea: {
    width: '100%',
    flexBox: true,
    justifyContent: 'center',
    mb: '60px',
  },
  description: {
    fontSize: ['12px', '12px', '12px', '14px', '14px'],
    color: 'black',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#00865b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  logoStyle: {
    maxWidth: ['70px !important', '100px !important'],
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'tertiaryWithBg',
    minWidth: ['auto', '120px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  fillInput: {
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    minWidth: ['auto', '200px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default FeatureBannerSection;
